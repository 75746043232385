import { IconProps, defaultIconProps } from './IconProps';

export const IconGlobe4: import('react').FC<IconProps> = ({ size, color = 'currentColor', ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_10989_21892)">
      <path
        d="M2.56365 6.23552L6.13701 8.78788C6.32295 8.92069 6.41592 8.9871 6.51322 9.0024C6.59884 9.01586 6.68652 9.0023 6.76406 8.96358C6.85219 8.91959 6.92074 8.82819 7.05784 8.64539L7.81292 7.6386C7.85169 7.58691 7.87108 7.56106 7.89386 7.53876C7.91409 7.51897 7.93629 7.50128 7.9601 7.48598C7.98693 7.46876 8.01645 7.45563 8.0755 7.42939L11.2993 5.99658C11.433 5.93718 11.4998 5.90749 11.5502 5.86111C11.5947 5.82009 11.6298 5.76984 11.653 5.71387C11.6792 5.65059 11.684 5.57763 11.6938 5.43172L11.9298 1.89101M11.2503 11.2498L13.4303 12.1841C13.6833 12.2925 13.8097 12.3467 13.8767 12.4368C13.9355 12.5157 13.9644 12.613 13.9583 12.7112C13.9514 12.8232 13.8751 12.9377 13.7224 13.1667L12.6982 14.703C12.6259 14.8115 12.5897 14.8658 12.5419 14.9051C12.4996 14.9399 12.4508 14.966 12.3984 14.9819C12.3392 14.9999 12.274 14.9999 12.1435 14.9999H10.4808C10.308 14.9999 10.2217 14.9999 10.1481 14.9709C10.0832 14.9454 10.0258 14.904 9.98101 14.8505C9.93034 14.7899 9.90302 14.7079 9.84838 14.544L9.25432 12.7618C9.22209 12.6651 9.20597 12.6168 9.20173 12.5677C9.19797 12.5241 9.20109 12.4802 9.21097 12.4376C9.22212 12.3896 9.24491 12.344 9.29049 12.2528L9.74175 11.3503C9.83343 11.167 9.87928 11.0753 9.94989 11.0183C10.0121 10.9682 10.0875 10.937 10.1669 10.9284C10.2571 10.9188 10.3544 10.9512 10.5489 11.016L11.2503 11.2498ZM18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10989_21892">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconGlobe4.defaultProps = defaultIconProps;
