import { css } from '@linaria/core';
import { IconAWS, IconGCP, IconAzure, IconContainer, IconGlobe4 } from 'components/Icons';

// HACK: This is temporary and should be done by the backend eventually
export const removeAcceleratorVendorPrefix = (acceleratorType: string[] | undefined) => {
  if (!acceleratorType) return '';
  return acceleratorType[0].replace('NVIDIA-', '');
};
export const computeDocumentation = {
  setUp: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/',
  createNodepool: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/#how-to-create-a-nodepool',
  instanceTypes: 'https://docs.clarifai.com/portal-guide/compute-orchestration/set-up-compute/#instance-types',
  marketingPage: 'https://www.clarifai.com/products/compute-orchestration'
};
const providerOptionStyles = "odules__User__components__Settings__ComputeClusters__utils_tsx-providerOptionStyles-p1hrkfph";
export const COMPUTE_PROVIDER_REGION_MAP: {
  [key: string]: string[];
} = {
  aws: ['us-east-1', 'us-west-1'],
  gcp: ['us-central1', 'us-east1', 'us-east4'],
  azure: ['eastus', 'westus'],
  local: ['na']
};
export const REGION_ICON_MAP: {
  [key: string]: string;
} = {
  us: '🇺🇸',
  ca: '🇨🇦'
  // ... add more regions and their corresponding icons
};
type ComputeProviderType = {
  icon: JSX.Element;
  name: string;
  handle: string;
};
type ComputeProviderDisplayComponent = {
  icon: JSX.Element | string;
  text: string;
};
type ComputeProviderDataType = {
  name: string;
  component: JSX.Element;
};
export type ComputeProvider = string;
export type ComputeRegion = string;
export const COMPUTE_PROVIDER_INFO_MAP: {
  [key: string]: ComputeProviderType;
} = {
  aws: {
    icon: <IconAWS size={20} />,
    name: 'Amazon Web Services',
    handle: 'AWS'
  },
  gcp: {
    icon: <IconGCP size={20} />,
    name: 'Google Cloud Platform',
    handle: 'GCP'
  },
  azure: {
    icon: <IconAzure size={20} />,
    name: 'Microsoft Azure',
    handle: 'Azure'
  },
  local: {
    icon: <IconContainer size={20} color="#667085" />,
    name: 'Local Cluster',
    handle: 'Local'
  }
  // 👆 Add mappings for additional providers here...
};

/**
 * Get the provider info for a given provider ID
 * @param providerId - the provider ID to get info for
 * @param useShortName - whether to use the short name for the provider
 * @returns the provider info, including the display component
 */
export const getComputeProviderInfo = (providerId: string, useShortName?: boolean) => {
  const providerDetails = COMPUTE_PROVIDER_INFO_MAP[providerId];

  // If we don't have a provider mapping, return a default fallback:
  if (!providerDetails) {
    return {
      name: providerId,
      component: <CloudProviderInfo icon={<IconContainer size={20} color="#667085" />} text={providerId} />
    };
  }

  // We can optionally use a shorter display name for tight spaces:
  const providerText = useShortName ? providerDetails.handle : providerDetails.name;
  const providerComponent = <CloudProviderInfo icon={providerDetails.icon} text={providerText} />;

  // Otherwise, return the provider details with the generated display component:
  return {
    ...providerDetails,
    component: providerComponent
  };
};

/**
 * Get the region info for a given region ID
 * @param regionId - the region ID to get info for
 * @returns the region info, including the display component
 */
export const getComputeRegionInfo = (regionId: string) => {
  const regionIcon = getRegionIcon(regionId);
  const formattedRegionId = getFormattedRegionId(regionId);
  return {
    name: regionId,
    component: <CloudRegionInfo icon={regionIcon} text={formattedRegionId} />
  };
};

/**
 * Get the formatted region ID for a given region ID, varies by use case.
 * Ensures only one rule is applied per condition.
 * @param regionId - the region ID to format
 * @returns the formatted region ID
 */
const getFormattedRegionId = (regionId: string): string => {
  // Define an array of rules as objects
  const formattingRules = [{
    condition: (id: string) => id === 'na',
    transform: (_id: string) => 'N/A'
  }
  // Future rules can be added here...
  // {
  //   condition: (id: string) => id.startsWith('us-'),
  //   transform: (id: string) => id.replace('us-', 'USA-'),
  // },
  ];

  // Apply the first matching rule
  for (const rule of formattingRules) {
    if (rule.condition(regionId)) {
      return rule.transform(regionId);
    }
  }

  // Default return if no rules match
  return regionId;
};
const CloudProviderInfo = ({
  icon,
  text
}: ComputeProviderDisplayComponent) => {
  return <div className={providerOptionStyles}>
      <div className="provider-icon">{icon}</div>
      <div className="provider-text">{text}</div>
    </div>;
};
const CloudRegionInfo = ({
  icon,
  text
}: ComputeProviderDisplayComponent) => {
  return <div className={providerOptionStyles}>
      <div className="region-icon">{icon}</div>
      <div className="region-text">{text}</div>
    </div>;
};
const getRegionIcon = (region: string) => {
  const normalizedRegion = region?.toLowerCase();
  for (const key in REGION_ICON_MAP) {
    if (normalizedRegion?.includes(key)) {
      return REGION_ICON_MAP[key];
    }
  }
  // Fallback icon...
  return <IconGlobe4 size={20} color="#667085" />;
};
export const getProviderData = (providerId: ComputeProvider, regionId: ComputeRegion): [ComputeProviderDataType, ComputeProviderDataType] => {
  const cloudProvider = getComputeProviderInfo(providerId);
  const cloudRegion = getComputeRegionInfo(regionId);
  return [cloudProvider, cloudRegion];
};
export const getFormattedDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

require("./utils.linaria.module.css!=!../../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./utils.tsx");