import { fetchApi } from 'utils/request';

type FetchResourcePricingParams = {
  userId: string;
  appId: string;
  queryParam: string;
};

interface IFetchResourcePricingResponse {
  status: CF.API.Status;
  price: string;
}

export const fetchResourcePricing = ({ userId, appId, queryParam }: FetchResourcePricingParams): Promise<IFetchResourcePricingResponse> =>
  fetchApi({
    path: `/api/_api/v2/users/${userId}/apps/${appId}/resource_price?${queryParam}`,
    method: 'GET',
  });
