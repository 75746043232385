import { defaultIconProps, IconProps } from './IconProps';

export const IconTwinStar: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_20_22102)">
      <path
        d="M4.33331 8.66659L4.85629 9.71253C5.03328 10.0665 5.12177 10.2435 5.24 10.3969C5.34491 10.533 5.46692 10.655 5.60302 10.7599C5.75639 10.8781 5.93338 10.9666 6.28737 11.1436L7.33331 11.6666L6.28737 12.1896C5.93338 12.3666 5.75639 12.455 5.60302 12.5733C5.46692 12.6782 5.34491 12.8002 5.24 12.9363C5.12177 13.0897 5.03328 13.2667 4.85629 13.6206L4.33331 14.6666L3.81034 13.6206C3.63335 13.2667 3.54485 13.0897 3.42663 12.9363C3.32172 12.8002 3.19971 12.6782 3.06361 12.5733C2.91024 12.455 2.73324 12.3666 2.37926 12.1896L1.33331 11.6666L2.37926 11.1436C2.73324 10.9666 2.91024 10.8781 3.06361 10.7599C3.19971 10.655 3.32172 10.533 3.42663 10.3969C3.54485 10.2435 3.63335 10.0665 3.81034 9.71253L4.33331 8.66659Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <path
        d="M9.99998 1.33325L10.7857 3.3762C10.9737 3.86502 11.0677 4.10943 11.2139 4.31501C11.3435 4.49722 11.5027 4.65642 11.6849 4.78598C11.8905 4.93216 12.1349 5.02616 12.6237 5.21417L14.6666 5.99992L12.6237 6.78567C12.1349 6.97367 11.8905 7.06768 11.6849 7.21386C11.5027 7.34342 11.3435 7.50262 11.2139 7.68482C11.0677 7.89041 10.9737 8.13482 10.7857 8.62364L9.99998 10.6666L9.21423 8.62364C9.02622 8.13482 8.93222 7.89041 8.78604 7.68482C8.65648 7.50262 8.49728 7.34342 8.31507 7.21386C8.10949 7.06768 7.86508 6.97367 7.37626 6.78567L5.33331 5.99992L7.37626 5.21417C7.86508 5.02616 8.10949 4.93216 8.31507 4.78598C8.49728 4.65642 8.65648 4.49722 8.78604 4.31501C8.93222 4.10943 9.02622 3.86502 9.21423 3.3762L9.99998 1.33325Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
    </g>
    <defs>
      <clipPath id="clip0_20_22102">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconTwinStar.defaultProps = defaultIconProps;
