import { fetchResourcePricing } from 'api/resource/fetchResourcePricing';
import { useIsLoggedIn } from 'modules/Auth/hooks';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useQuery } from 'react-query';
import { queryKeys } from 'utils/react-query';

type Params = {
  userId: string;
  appId: string;
  queryParam: string;
};

export const useResourcePricing = ({ userId, appId, queryParam }: Params) => {
  const isLoggedIn = useIsLoggedIn();
  const { 'enable-resource-pricing': enableResourcePricing } = useFeatureFlagsLD();
  const isResourcePricingEnabled = enableResourcePricing || !isLoggedIn;
  const { data } = useQuery({
    enabled: Boolean(isResourcePricingEnabled),
    queryKey: [queryKeys.resourcePricing, { userId, appId, queryParam }],
    queryFn: () => fetchResourcePricing({ userId, appId, queryParam }),
  });

  return { data };
};
