import { Status } from 'clarifai-web-grpc/proto/clarifai/api/status/status_pb';
import { fetchApi } from 'utils/request';

export interface GenerateImageResponse {
  status: Status;
  outputs: [
    {
      data: {
        image: {
          base64: string;
        };
      };
    },
  ];
}

export const generateImage = ({ rawText, PAT }: { rawText: string; PAT: string }) =>
  fetchApi<GenerateImageResponse>({
    path: `/api/getCoverImage`,
    method: 'POST',
    body: {
      inputs: [
        {
          data: {
            text: {
              raw: rawText,
            },
          },
        },
      ],
      PAT,
    },
  });
