// TODO: add unit test to cover this branch https://clarifai.atlassian.net/browse/MRK-1443
/* istanbul ignore file */
import cogoToast from 'cogo-toast';
import { either, taskEither } from 'fp-ts';
import { applySnapshot, types } from 'mobx-state-tree';
import { errorToReactLeft, noopTask, pipe } from 'utils/fp';
import { UI_STATES } from 'utils/uiStates/uiStates';
import { updateMST, withRunInAction } from 'utils/withRunInAction';
import { toJS } from 'mobx';
import { createKeyTE, updateKeyTE, listKeysTE, deleteKeyTE } from 'api/keys';
import { APIKeyType, KeyTypeValue } from 'modules/AppSettings/ApiKeysTable/constants';

const PAT_KEY_VALUE = 'personal_access_token' as const;

export enum KeyCreationEventType {
  ERROR,
  SUCCESS,
  CANCELLED,
}

const ToggleVisibilityMST = types
  .model({
    visibleItemMap: types.map(types.boolean),
  })
  .actions((self) => ({
    toggleKeyVisibility: (apiKeyId: string) => {
      // TODO: add unit test to cover this branch https://clarifai.atlassian.net/browse/MRK-1443
      /* istanbul ignore next */
      if (self.visibleItemMap.has(apiKeyId)) {
        self.visibleItemMap.delete(apiKeyId);
      } else {
        self.visibleItemMap.set(apiKeyId, true);
      }
    },
  }))
  .views((self) => ({
    getIsVisible: (apiKeyId: string) => self.visibleItemMap.has(apiKeyId),
  }));

const AppApiKeysModel = types
  .model({
    apiKeys: types.array(types.frozen<CF.API.ApiKeys.KeyData>()),
    fetchStatus: types.maybe(types.enumeration(Object.values(UI_STATES))),
  })
  .named('ApiKeyTableMST')
  .views((self) => ({
    get listApiKeys() {
      if (self.fetchStatus) {
        return either.left({ type: self.fetchStatus });
      }

      if (!self.apiKeys.length) {
        return either.left({ type: UI_STATES.empty });
      }

      return either.right(self.apiKeys.map((apiKey) => apiKey));
    },

    get listAccessTokens() {
      if (self.fetchStatus) {
        return either.left({ type: self.fetchStatus });
      }

      if (!self.apiKeys.length) {
        return either.left({ type: UI_STATES.empty });
      }

      return either.right(self.apiKeys.filter((apiKey) => apiKey.type === KeyTypeValue.PATKey));
    },
  }));

export const UserKeysMST = types
  .compose(withRunInAction(), ToggleVisibilityMST, AppApiKeysModel)
  .named('UserKeysMST')
  .actions((self) => ({
    _addApiKeyToList: (apiKey: CF.API.ApiKeys.KeyData) =>
      updateMST(self, () => {
        self.apiKeys.unshift(apiKey);
      }),
  }))
  .actions((self) => ({
    load: ({ userId, appId }: { userId: string; appId?: string }) => {
      self.fetchStatus = UI_STATES.loading;

      return pipe(
        listKeysTE({ userId, appId }, errorToReactLeft),
        taskEither.fold(
          // TODO: add unit test to cover this branch https://clarifai.atlassian.net/browse/MRK-1443
          /* istanbul ignore next */
          ({ type }) => {
            updateMST(self, () => {
              self.fetchStatus = type;
            });
            return noopTask;
          },
          (data) => {
            updateMST(self, () => {
              applySnapshot(self, { fetchStatus: undefined });
              self.fetchStatus = undefined;
              self.apiKeys.replace(data.keys);
            });
            return noopTask;
          },
        ),
      )();
    },

    createNewKey: ({
      userId,
      scopes,
      endpoints,
      description,
      callback,
      ...rest
    }:
      | (
          | {
              // app-level api key
              userId: string;
              scopes: string[];
              endpoints: string[];
              appId: string;
              description: string;
            }
          | {
              // personal access token
              type: CF.API.ApiKeys.PAT_KEY_TYPE;
              userId: string;
              scopes: string[];
              endpoints: string[];
              description: string;
            }
        ) & { callback?: (eventType: KeyCreationEventType) => void }) => {
      const baseParams = { userId, scopes, endpoints, description };
      const params =
        'appId' in rest
          ? {
              ...baseParams,
              appId: rest.appId,
            }
          : {
              ...baseParams,
              type: PAT_KEY_VALUE,
            };

      return pipe(
        createKeyTE(params, errorToReactLeft),
        taskEither.fold(
          (e) => {
            cogoToast.error(e.props?.reason);

            if (callback) {
              callback(KeyCreationEventType.ERROR);
            }

            return noopTask;
          },
          ({ keys: [apiKey] }) => {
            cogoToast.success(`Key ${apiKey.description || ''} created!`);

            updateMST(self, () => self._addApiKeyToList(apiKey));

            if (callback) {
              callback(KeyCreationEventType.SUCCESS);
            }

            return noopTask;
          },
        ),
      )();
    },

    deleteKey: (params: { userId: string; id: string }) => {
      const apiKey = self.apiKeys.find((k) => k.id === params.id);
      /* istanbul ignore if: apiKey for the TS compiler can be undefined due to nature of Array.find; doesn't need branch coverage */
      if (apiKey) {
        const removedApiKey = toJS(apiKey) as CF.API.ApiKeys.KeyData; // TODO: Try to understand what wrong here with types
        self.apiKeys.remove(apiKey);

        pipe(
          deleteKeyTE(params, errorToReactLeft),
          taskEither.fold(
            (e) => {
              cogoToast.error(e.props?.reason);

              updateMST(self, () => self._addApiKeyToList(removedApiKey));

              return noopTask;
            },
            () => {
              cogoToast.success('Key removed!');
              return noopTask;
            },
          ),
        )();
      }

      return null;
    },
  }))
  .actions((self) => ({
    updateKey: ({
      type,
      userId,
      appId,
      keyId,
      scopes,
      endpoints,
      description,
    }: {
      type: CF.API.ApiKeys.KeyType;
      userId: string;
      appId?: string;
      keyId: string;
      scopes: string[];
      endpoints: string[];
      description: string;
    }) => {
      const baseParams: {
        userId: string;
        scopes: string[];
        endpoints: string[];
        description: string;
        appId?: string;
      } = { userId, scopes, endpoints, description };
      if (appId) {
        baseParams.appId = appId;
      }
      const params =
        type === APIKeyType
          ? {
              ...baseParams,
              keyId,
            }
          : {
              ...baseParams,
              type: PAT_KEY_VALUE,
              keyId,
            };

      return pipe(
        updateKeyTE(params, errorToReactLeft),
        taskEither.fold(
          (e) => {
            cogoToast.error(e.props?.reason);
            return noopTask;
          },
          ({ keys: [apiKey] }) => {
            cogoToast.success(`Key ${apiKey.description || ''} updated!`);
            updateMST(self, () => self.load({ userId: baseParams.userId, appId }));
            return noopTask;
          },
        ),
      )();
    },
  }));

export type TUserKeysMST = ReturnType<typeof UserKeysMST.create>;

export const createAppTokensStore = (): TUserKeysMST => {
  return UserKeysMST.create({ fetchStatus: UI_STATES.loading });
};
