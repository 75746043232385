import { css } from '@linaria/core';
import { theming } from 'styles/utils';
export const spinnerDiv = "omponents__CoverImage__CoverImage_styles_ts-spinnerDiv-s13l7vhi";
export const noImageApp = "omponents__CoverImage__CoverImage_styles_ts-noImageApp-n1tgmwy5";
export const fileUploadArea = "omponents__CoverImage__CoverImage_styles_ts-fileUploadArea-fcvi73w";
export const uploadAreaText = "omponents__CoverImage__CoverImage_styles_ts-uploadAreaText-u1v0g79j";
export const removeButton = "omponents__CoverImage__CoverImage_styles_ts-removeButton-r1ets5by";
export const thumbnail = "omponents__CoverImage__CoverImage_styles_ts-thumbnail-t1ih59sa";
export const shimmerLoader = "omponents__CoverImage__CoverImage_styles_ts-shimmerLoader-s1opw8oc";

require("./CoverImage.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./CoverImage.styles.ts");