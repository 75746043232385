import { cookieClientStorage } from 'utils/cookieStorage';

export const HUBSPOT_FORM_IDS = {
  signUpForm: '45aa29dd-e4e0-474a-b474-162409ae7962',
};

export const HUBSPOT_CUSTOM_EVENTS_NAME = {
  signupFormSubmitted: 'pe4505120_signup_form_submitted',
  lastVisit: 'pe4505120_last_visited',
  firstVisit: 'pe4505120_first_visited',
  signupCompletedDate: 'pe4505120_signup_completed_date',
  firstAppCreated: 'pe4505120_first_app_created',
};

export const submitFormApi = ({ formId, fields }: { formId: string; fields: { name: string; value: string }[] }): void => {
  const hutk = cookieClientStorage.get('hubspotutk');
  const pageName = document.title;
  const pageUri = document.location.href;

  const body = JSON.stringify({
    ...(hutk ? { hutk } : {}),
    pageUri,
    pageName,
    fields,
    formId,
  });

  const formUrl = '/api/hs/submissions';
  fetch(formUrl, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const sendEvent = ({ email, eventName, properties }: { email?: string; eventName: string; properties: Record<string, string> }): void => {
  const url = '/api/hs/send';
  const body = {
    email,
    eventName,
    properties,
  };

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
