import { defaultIconProps, IconProps } from './IconProps';

export const IconGlobe2: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M11 1C13.5013 3.73835 14.9228 7.29203 15 11C14.9228 14.708 13.5013 18.2616 11 21M11 1C8.49872 3.73835 7.07725 7.29203 7 11C7.07725 14.708 8.49872 18.2616 11 21M11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21M11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21M1.50002 8H20.5M1.5 14H20.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconGlobe2.defaultProps = defaultIconProps;
