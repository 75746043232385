import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const modelConfig = {
  development: {
    user_id: 'erfan',
    app_id: 'generative-art',
    modelId: 'dalle-mini',
    modelVersionId: '2ebd5eebedfc4d8c9f2ac8c5198b10ff',
    api: 'https://api-dev.clarifai.com/v2/models',
  },
  production: {
    user_id: 'openai',
    app_id: 'dall-e',
    modelId: 'dall-e-3',
    modelVersionId: 'dc9dcb6ee67543cebc0b9a025861b868',
    api: 'https://api.clarifai.com/v2/models',
  },
  staging: {
    modelId: null,
    modelVersionId: null,
    api: null,
    user_id: null,
    app_id: null,
  },
};

export const getCoverImageModelsByEnv = () => {
  return { ...modelConfig[publicRuntimeConfig.CF_ENV as keyof typeof modelConfig], environment: publicRuntimeConfig.CF_ENV };
};
