export interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number;
  color?: string;
  strokeWidth?: number;
  stroke?: string;
  width?: number;
  height?: number;
}

export const defaultIconProps: IconProps = {
  size: 20,
  color: 'currentColor',
};
