export const APIKeyType = 'API_KEY';
export const PATKeyType = 'PAT';
export const KeyTypeValue = {
  APIKey: 'app_specific_key',
  PATKey: 'personal_access_token',
};
export const modalKeys = {
  apiKey: {
    add: 'add_new_api_key',
    edit: 'edit_api_key',
    delete: 'delete_api_key',
  },
  patKey: {
    add: 'add_new_pat',
    edit: 'edit_pat',
    delete: 'delete_api_key',
  },
};
