import { defaultIconProps, IconProps } from './IconProps';

export const IconLogs: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
    <path
      d="M11.6693 9.16663H6.66927M8.33594 12.5H6.66927M13.3359 5.83329H6.66927M16.6693 5.66663V14.3333C16.6693 15.7334 16.6693 16.4335 16.3968 16.9683C16.1571 17.4387 15.7747 17.8211 15.3042 18.0608C14.7695 18.3333 14.0694 18.3333 12.6693 18.3333H7.33594C5.93581 18.3333 5.23574 18.3333 4.70096 18.0608C4.23056 17.8211 3.8481 17.4387 3.60842 16.9683C3.33594 16.4335 3.33594 15.7334 3.33594 14.3333V5.66663C3.33594 4.26649 3.33594 3.56643 3.60842 3.03165C3.8481 2.56124 4.23056 2.17879 4.70096 1.93911C5.23574 1.66663 5.93581 1.66663 7.33594 1.66663H12.6693C14.0694 1.66663 14.7695 1.66663 15.3042 1.93911C15.7747 2.17879 16.1571 2.56124 16.3968 3.03165C16.6693 3.56643 16.6693 4.26649 16.6693 5.66663Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconLogs.defaultProps = defaultIconProps;
