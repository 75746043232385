import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { propOr } from 'rambda';
import { cx } from '@linaria/core';
import { makeUserFullName } from 'utils/users';
import { stringToColor, makeInitials } from 'utils/strings/strings';
import { withAuth } from 'hocs/withAuth/withAuth';
import { useAuthActions, useAuthState } from 'modules/Auth/AuthContext';
import { useCurrentUser, useIsOrgAccount } from 'modules/Auth/hooks';
import { Link } from 'components/Link';
import { Button, IconButton } from 'components/Button';
import { TextIcon } from 'components/TextIcon';
import { usePopoverState, PopoverAction, PopoverActionProps, TextNormal } from 'components/Popover';
import { IconQuestion, IconPlus, IconHamburgerMenu2 } from 'components/Icons';
import { AccountPopover, MobileAccountItems } from 'components/Navbar/AccountPopover';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { useCreateApplicationModal } from 'modules/Apps/hooks';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { useToggle } from 'react-use';
import { useOrganizationInvitesList } from 'modules/Organizations/hooks';
import { AuthModalState } from 'modules/Auth/components/AuthModal';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { ORGANIZATION_ROLE_ID_ADMIN } from 'modules/Organizations/constants';
import { computeDocumentation } from 'modules/User/components/Settings/ComputeClusters/utils';
import { CalloutBadge } from 'components/Badge/CalloutBadge';
import { logo, profile, navBarDropdown, nav, navContent, navLink__Text, navLink__Active, navLink__Radius, navAuthWrapper, hideBelow900, btnNoBorder, iconsTray, hideAbove900, createAppBtn, pointer, spacer } from './styles';
import { HelpPopover, MobileHelpItems } from './HelpPopover';
import { JiraButton } from './JiraButton';
import { NotificationButton } from './Notifications';
import { useActiveNavLinkType, NavLinkType } from './Navbar.hooks';
interface NavLinkProps extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'href'> {
  href: string;
  active?: boolean;
  currentPath?: string;
  query?: Record<string, string>;
  isText?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
}
interface HelpDropdownProps extends PopoverActionProps {
  isMobileVisible: boolean;
  toggleVisibility: () => void;
}
export const NavLink: React.FC<NavLinkProps> = ({
  href,
  children,
  active,
  query,
  currentPath,
  isText,
  ...props
}) => {
  const isTextItem = isText || typeof children === 'string';
  return <Link href={{
    pathname: href,
    query: query || (currentPath ? {
      from: currentPath
    } : undefined)
  }} className={cx(navLink__Active, active && 'active', isTextItem && cx(navLink__Radius, navLink__Text))} {...props}>
      {children}
    </Link>;
};
const HelpDropdown: React.FC<HelpDropdownProps> = ({
  isMobileVisible,
  toggleVisibility,
  popover
}) => {
  return <>
      <IconButton key="help-popover-mobile" aria-label="help" variant="tertiary" size={20} Icon={IconQuestion} onClick={toggleVisibility} className={cx(btnNoBorder, hideAbove900, navBarDropdown, navLink__Active, navLink__Radius, isMobileVisible && 'active')} />

      <PopoverAction popover={popover} className={hideBelow900}>
        <IconButton key="help-popover-desktop" aria-label="help" variant="tertiary" size={20} Icon={IconQuestion} className={cx(btnNoBorder, navBarDropdown, navLink__Active, navLink__Radius, popover.visible && 'active')} />
      </PopoverAction>
    </>;
};
export const NavbarInternal: React.FC<{
  isLoggedIn?: boolean;
}> = ({
  isLoggedIn
}) => {
  const {
    logout,
    openLoginModal
  } = useAuthActions();
  const {
    authData
  } = useAuthState();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const {
    controlCenter,
    computeMerchandisingLDFF,
    nodePoolDeploymentsLDFF
  } = useFeatureFlagsLD();
  const {
    track
  } = useDataCollection();
  const {
    load
  } = useCurrentStore();
  const orgInvitesList = useOrganizationInvitesList();
  const accountPopover = usePopoverState({
    placement: 'bottom-end'
  });
  const helpPopover = usePopoverState({
    placement: 'bottom-end'
  });
  const userFullName = currentUser ? makeUserFullName(currentUser) : '';
  const currentPath = router.asPath;
  const userId = authData && propOr('', 'user_id')(currentUser);
  const [showProfile, setShowProfile] = useState(false);
  const activeNavLink = useActiveNavLinkType();
  const {
    canCreateApplication,
    openCreateApplicationModal
  } = useCreateApplicationModal({
    onAppCreated: (createdApp, fromTemplate = false) => {
      load();
      router.push(`/${createdApp.userId}/${createdApp.appId}${!fromTemplate ? '?show_wizard_model_path_modal=true' : ''}`);
      track('User App Created', {
        context: 'regular'
      });
    }
  });
  const [mobileAccountDropdownVisible, toggleMobileAccountDropdown] = useToggle(false);
  const [mobileHelpDropdownVisible, toggleHelpAccountDropdown] = useToggle(false);
  const isOrg = useIsOrgAccount();
  const currentOrg = useCurrentUserOrg();
  const isOrgAdmin = currentOrg?.role?.id === ORGANIZATION_ROLE_ID_ADMIN;

  // to ensure react doesnt show hydration mismatch on client vs server render
  useEffect(() => {
    setShowProfile(Boolean(isLoggedIn));
  }, [isLoggedIn]);
  const handleLogout = useCallback((event?: React.MouseEvent) => {
    event?.preventDefault();
    accountPopover.hide();
    logout();
  }, [logout, accountPopover]);
  const dynamicComputeUrl = nodePoolDeploymentsLDFF ? '/settings/compute' : computeDocumentation.marketingPage;
  const onHeaderBtnClick = (btnText: string): void => {
    track('Navbar Button Clicked', {
      text: btnText,
      context: 'regular'
    });
  };
  const handleCreateApp = (): void => {
    onHeaderBtnClick('create_app');
    router.push({
      query: {
        ...router.query,
        run_create_app: true
      }
    }, undefined, {
      shallow: true
    });
    openCreateApplicationModal();
  };
  return <nav className={nav} data-testid="navbar">
      <div className={navContent}>
        <Link href="/explore" className={cssHelpers.height.content}>
          <div className={logo} data-testid="clarifai-logo" />
        </Link>

        {activeNavLink !== NavLinkType.pendingVerification && <div className={cx(flex.init, flex.alignItems.center, 'fullWidth')}>
            <ul className={cx(flex.init, flex.alignItems.center, flex.gap.quarter, hideBelow900)}>
              {showProfile && <NavLink data-testid="headerNavProfile" href={`/${userId}`} active={activeNavLink === NavLinkType.profile} key="profile">
                  {isOrg ? 'Org Apps' : 'My Apps'}
                </NavLink>}
              {showProfile && !isOrg && <NavLink data-testid="headerNavCommunity" href={`/${userId}/collaborations`} active={activeNavLink === NavLinkType.collaborations} key="collaborations">
                  Collaborations
                </NavLink>}
              <NavLink data-testid="headerNavCommunity" href="/explore" active={activeNavLink === NavLinkType.community} key="community">
                Community
              </NavLink>
              {showProfile && controlCenter && (!isOrg || isOrgAdmin) && <NavLink data-testid="headerNavCommunity" href="/control-center" active={activeNavLink === NavLinkType.controlCenter} key="control-center">
                  Control Center
                </NavLink>}
              {computeMerchandisingLDFF && (!isOrg || isOrgAdmin) && <NavLink href={dynamicComputeUrl} active={activeNavLink === NavLinkType.compute} key="compute" isText target={nodePoolDeploymentsLDFF ? '_self' : '_blank'}>
                  Compute
                  <CalloutBadge color="#b54708" background="#fef0c7">
                    Preview
                  </CalloutBadge>
                </NavLink>}
            </ul>

            <div className={spacer} />

            {showProfile ? <ButtonGroup key="logged-in" className={navAuthWrapper} style={{
          minWidth: 0
        }}>
                <ButtonGroup key="icons-tray" className={iconsTray}>
                  <HelpDropdown isMobileVisible={mobileHelpDropdownVisible} popover={helpPopover} toggleVisibility={toggleHelpAccountDropdown} />
                </ButtonGroup>

                <NotificationButton key="notification-button" orgInvitesList={orgInvitesList} />

                <JiraButton collectorId="257d76d7" clickTargetSelector="#atlwdg-trigger.atlwdg-SUBTLE">
                  <TextNormal className={pointer}>Send Feedback</TextNormal>
                </JiraButton>

                {canCreateApplication && <NavLink href={`/${userId}/apps/new`}>
                    <Button key="create-an-app" LeftIcon={IconPlus} variant="tertiary" size="md" onClick={e => {
              e.preventDefault();
              handleCreateApp();
            }} className={cx(hideBelow900, createAppBtn)}>
                      Create
                    </Button>
                  </NavLink>}

                <IconButton key="account-popover" aria-label="help" variant="tertiary" size={20} Icon={IconHamburgerMenu2} onClick={toggleMobileAccountDropdown} className={cx(btnNoBorder, hideAbove900, navBarDropdown, navLink__Active, navLink__Radius, mobileAccountDropdownVisible && 'active')} />

                <PopoverAction popover={accountPopover} className={hideBelow900} data-testid="navbar-account-avatar">
                  <TextIcon key="account-popover" text={makeInitials(userFullName)} background={stringToColor(userFullName)} className={cx(profile, accountPopover.visible && 'active')} />
                </PopoverAction>

                {authData && <AccountPopover popover={accountPopover} onLogout={handleLogout} />}
              </ButtonGroup> : <ButtonGroup key="logged-out" className={navAuthWrapper}>
                <HelpDropdown isMobileVisible={mobileHelpDropdownVisible} popover={helpPopover} toggleVisibility={toggleHelpAccountDropdown} />

                <NavLink href="/login" currentPath={currentPath}>
                  <Button variant="tertiary" size="md" onClick={e => {
              e.preventDefault();
              onHeaderBtnClick('Login');
              openLoginModal();
            }}>
                    Log in
                  </Button>
                </NavLink>

                {process.env.NEXT_PUBLIC_SIGN_UP_DISABLED !== 'true' && <NavLink href="/signup" currentPath={currentPath}>
                    <Button size="md" onClick={e => {
              e.preventDefault();
              onHeaderBtnClick('Signup');
              openLoginModal(AuthModalState.SHOW_SIGN_UP);
            }}>
                      Sign up
                    </Button>
                  </NavLink>}
              </ButtonGroup>}

            <HelpPopover popover={helpPopover} canReport={showProfile} />
          </div>}
      </div>
      {mobileAccountDropdownVisible && <MobileAccountItems closePopup={() => toggleMobileAccountDropdown(false)} canCreateApp={canCreateApplication} handleCreateApp={handleCreateApp} onLogout={handleLogout} />}
      {mobileHelpDropdownVisible && <MobileHelpItems canReport={showProfile} closePopup={() => toggleHelpAccountDropdown(false)} />}
    </nav>;
};
export const Navbar = withAuth<{
  isLoggedIn?: boolean;
}>()(NavbarInternal);