import { generateImage } from 'api/apps/generateImage';
import { createAppTokensStore } from 'modules/Apps/mstStores/UserKeysMST';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useMstInstanceCreator } from 'utils/mst/useMstInstanceCreator';
import { queryKeys } from 'utils/react-query';
import { either } from 'fp-ts';
import cogoToast from 'cogo-toast';

type Params = {
  text: string;
  onSuccess: (data: { outputs: Array<{ data: { image: { base64: string } } }> }) => void;
};

export const useGenerateCoverImage = ({ text, onSuccess }: Params) => {
  const store = useMstInstanceCreator(createAppTokensStore, []);
  const userId = useCurrentUserId();
  const accessTokens = either.isRight(store.listAccessTokens)
    ? store.listAccessTokens.right.filter((token: CF.API.ApiKeys.KeyData) => {
        return token?.scopes?.includes('All');
      })
    : [];

  useEffect(() => {
    store.load({ userId });
  }, [userId]);

  const { refetch, isFetching: isGeneratingImage } = useQuery({
    enabled: false,
    queryKey: [queryKeys.GenerateImage, { rawText: text || '' }],
    queryFn: () => generateImage({ rawText: text || '', PAT: accessTokens[0].id }),
    onSuccess,
    onError: (error: { description: string }) => {
      cogoToast.error(error?.description ?? 'Error in generating cover image', { heading: 'Error' });
    },
  });

  return { generateImage: refetch, isGeneratingImage };
};
