import { omit } from 'rambda';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { TwoFactorAuthData, useAuthActions, useAuthState } from './AuthContext';

export const getIsEntityOwner = ({ userId }: Record<string, string>): boolean => {
  const user = useCurrentUser();
  const currentUserId = user ? user.user_id : '';
  return currentUserId === userId;
};

/**
 * Returns the user account which is currently being used (either logged-in user or org account).
 *
 * NOTE: If you need to use the logged-in user details always, use "useAuthState" hook instead.
 *
 */
export function useCurrentUser(): CF.LocalUser | undefined {
  const { authData } = useAuthState();
  const isOrgAccount = useIsOrgAccount();

  if (!authData) return undefined;

  let localUser: CF.LocalUser = omit(['current_user_id', 'organizations'], authData);

  if (isOrgAccount) {
    const orgDetails = authData.organizations?.find(({ organization }) => organization.id === authData.current_user_id)?.organization;

    localUser = {
      ...localUser,
      ...(orgDetails ? { user_id: orgDetails.id, first_name: orgDetails.name, last_name: '', email: orgDetails.billing_email } : {}),
    };
  }

  return localUser;
}

export function useLoggedInUserId(): string {
  const { authData } = useAuthState();

  return authData?.user_id || '';
}

export function useTwoFactorAuthData(): TwoFactorAuthData | undefined {
  const { twoFactorAuthData } = useAuthState();
  return twoFactorAuthData;
}

/**
 * Warning: This function returns empty string if no logged in user.
 */

export function useCurrentUserId(): string {
  const currentUser = useCurrentUser();
  return currentUser?.user_id || '';
}

export function useIsLoggedIn(): boolean {
  const { authData } = useAuthState();
  return Boolean(authData);
}

export function useIsInternalUser(): boolean {
  const { authData } = useAuthState();
  const isInternalUser = authData?.email?.toLowerCase().endsWith('@clarifai.com');
  return Boolean(isInternalUser);
}

/**
 * Checks if current user account is an organization account or logged-in user account
 * (Optional) userId: checks for this userId instead of current userId
 */
export function useIsOrgAccount(userId?: string): boolean {
  const { authData } = useAuthState();
  const orgIds = authData?.organizations?.map(({ organization }) => organization.id);

  if (userId) {
    return Boolean(orgIds?.includes(userId));
  }

  const currentUserIdIsOrgId =
    authData?.current_user_id && authData.current_user_id !== authData.user_id && orgIds?.includes(authData.current_user_id);
  return Boolean(currentUserIdIsOrgId);
}

export function useUserNeeds2FA(): boolean {
  const { authData, twoFactorAuthData } = useAuthState();
  return Boolean(!authData?.session_token && twoFactorAuthData?.two_factor_auth_enabled);
}

export function useIsOnboardingPending(): boolean {
  const { authData } = useAuthState();
  return Boolean(authData?.is_onboarding_pending);
}

export function useSwitchUserAccount() {
  const router = useRouter();
  const { changeUserAccountById } = useAuthActions();

  return useCallback(
    (userOrOrgId?: string) => {
      if (!userOrOrgId) {
        return;
      }

      const pathWithoutQueryParams = router.asPath?.split('?')[0] || '';
      const isCommunityRoute = pathWithoutQueryParams.startsWith('/explore');

      changeUserAccountById(userOrOrgId);

      // Redirect only when if user isn't viewing one of the explore pages:
      // https://clarifai.atlassian.net/browse/MRK-2721
      if (!isCommunityRoute) {
        router.push(`/${userOrOrgId}/apps`);
      }
    },
    [changeUserAccountById, router],
  );
}
