import { defaultIconProps, IconProps } from './IconProps';

export const IconCard: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M18.3337 5.33317H1.66699M9.16699 8.6665H5.00033M1.66699 3.83317L1.66699 10.1665C1.66699 11.0999 1.66699 11.5666 1.84865 11.9232C2.00844 12.2368 2.2634 12.4917 2.57701 12.6515C2.93353 12.8332 3.40024 12.8332 4.33366 12.8332L15.667 12.8332C16.6004 12.8332 17.0671 12.8332 17.4236 12.6515C17.7372 12.4917 17.9922 12.2368 18.152 11.9232C18.3337 11.5666 18.3337 11.0999 18.3337 10.1665V3.83317C18.3337 2.89975 18.3337 2.43304 18.152 2.07652C17.9922 1.76292 17.7372 1.50795 17.4236 1.34816C17.0671 1.16651 16.6004 1.16651 15.667 1.1665L4.33366 1.1665C3.40024 1.1665 2.93353 1.1665 2.57701 1.34816C2.2634 1.50795 2.00844 1.76292 1.84865 2.07652C1.66699 2.43304 1.66699 2.89975 1.66699 3.83317Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconCard.defaultProps = defaultIconProps;
