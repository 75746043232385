import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

export const useLoginRouteHandler = () => {
  const {
    asPath,
    query: { userOrOrgId },
    pathname,
    replace,
  } = useRouter();

  const debouncedReplace = debounce(() => {
    const previousRoute = ref.current?.previous;
    const currentRoute = ref.current?.active;
    const path = previousRoute?.path.includes('/explore') ? currentRoute?.path : previousRoute?.path;
    if (pathname !== '/login') {
      replace(path ?? currentRoute?.path ?? '/explore');
    }
  }, 300);

  const ref = useRef<{ active: { path: string; userOrOrgId: string }; previous: { path: string; userOrOrgId: string } | null } | null>(null);

  useEffect(() => {
    ref.current = {
      active: { path: asPath, userOrOrgId: userOrOrgId as string },
      previous: ref?.current?.active || null,
    };
    return () => {
      debouncedReplace.cancel();
    };
  }, [asPath]);

  const handleLoginRedirection = () => {
    debouncedReplace?.cancel();
    debouncedReplace();
  };

  return { ...ref.current, handleLoginRedirection };
};
