import { defaultIconProps, IconProps } from './IconProps';

export const IconGCP: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    {...rest}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="surface1">
      <path
        fill="#EA4335"
        d="M 13.300781 6.390625 L 15.039062 4.652344 L 15.15625 3.921875 C 11.988281 1.039062 6.953125 1.367188 4.09375 4.601562 C 3.300781 5.5 2.714844 6.621094 2.398438 7.777344 L 3.023438 7.691406 L 6.5 7.117188 L 6.769531 6.84375 C 8.3125 5.144531 10.929688 4.917969 12.714844 6.363281 Z M 13.300781 6.390625 "
      />
      <path
        fill="#4285F4"
        d="M 17.515625 7.726562 C 17.117188 6.257812 16.296875 4.933594 15.15625 3.921875 L 12.714844 6.363281 C 13.746094 7.203125 14.332031 8.472656 14.308594 9.800781 L 14.308594 10.234375 C 15.507812 10.234375 16.480469 11.207031 16.480469 12.40625 C 16.480469 13.605469 15.507812 14.554688 14.308594 14.554688 L 9.957031 14.554688 L 9.53125 15.015625 L 9.53125 17.621094 L 9.957031 18.03125 L 14.308594 18.03125 C 17.425781 18.054688 19.976562 15.570312 20 12.449219 C 20.015625 10.558594 19.082031 8.789062 17.515625 7.726562 "
      />
      <path
        fill="#34A853"
        d="M 5.613281 18.03125 L 9.957031 18.03125 L 9.957031 14.554688 L 5.613281 14.554688 C 5.304688 14.554688 5.003906 14.488281 4.722656 14.359375 L 4.109375 14.546875 L 2.359375 16.285156 L 2.203125 16.878906 C 3.1875 17.621094 4.382812 18.035156 5.613281 18.03125 "
      />
      <path
        fill="#FBBC05"
        d="M 5.613281 6.753906 C 2.496094 6.769531 -0.0195312 9.316406 0 12.433594 C 0.0117188 14.175781 0.824219 15.816406 2.203125 16.878906 L 4.722656 14.359375 C 3.632812 13.863281 3.144531 12.578125 3.640625 11.484375 C 4.132812 10.394531 5.417969 9.90625 6.511719 10.402344 C 6.992188 10.617188 7.378906 11.003906 7.597656 11.484375 L 10.117188 8.964844 C 9.042969 7.566406 7.378906 6.746094 5.613281 6.753906 "
      />
    </g>
  </svg>
);

IconGCP.defaultProps = defaultIconProps;
