import { useMemo } from 'react';
import { IAnyType, Instance } from 'mobx-state-tree';
import { useAutoDestroyMst } from './useAutoDestroyMst';

export function useMstInstanceCreator<T>(
  instanceCreator: (...config: Parameters<IAnyType['create']>) => Instance<T>,
  deps: React.DependencyList,
  configFactory?: () => Parameters<IAnyType['create']>,
): Instance<T> {
  const model = useMemo(() => instanceCreator(...(configFactory?.() ?? [])), [instanceCreator, ...deps]);

  useAutoDestroyMst(model);

  return model;
}
