import { useResourcePricing } from 'hooks/useResourcePricing';
import { TextTooltip } from 'components/Tooltip';
import { tooltipCls } from 'modules/Models/components/ModelViewer/Header/Header.styles';
import { IconTwinStar } from 'components/Icons';
import { noop } from 'utils/fp';
import { MouseEventHandler } from 'react';
import { useCurrentUser, useIsInternalUser } from 'modules/Auth/hooks';
import { useFeatureFlagsLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import { getCoverImageModelsByEnv } from './modelConfig';

export const GenerateCoverImage = ({ onClick }: { onClick: MouseEventHandler<HTMLElement> }) => {
  const { generateCoverImage } = useFeatureFlagsLD();
  const isBillingOrInternalUser = (useCurrentUser() as unknown as { user: { bill_type: string } })?.user?.bill_type !== 'none' || useIsInternalUser();
  const { modelId, app_id, user_id } = getCoverImageModelsByEnv();
  const { data: resource } = useResourcePricing({ userId: user_id as string, appId: app_id as string, queryParam: `model.id=${modelId}` });
  const price = `$${Math.abs(Number(resource?.price ?? 0) / 100000)} / request`;
  const isHidden = !isBillingOrInternalUser || !modelId || !generateCoverImage;

  if (isHidden) return null;

  const tooltipContent = (
    <p style={{ padding: '0.5rem', wordBreak: 'normal' }}>
      Cover image generation is based on description, powered by <strong>{modelId}</strong> and costs <strong>{price}</strong>.
    </p>
  );

  return (
    <div className="generate-cover-image" onKeyPress={noop} tabIndex={0} role="button" onClick={onClick}>
      <TextTooltip arrowProps={{ className: 'darkArrow' }} showArrow className={tooltipCls} text={tooltipContent}>
        <IconTwinStar size={16} />
        <span className="bold">&nbsp; Generate cover image</span>
      </TextTooltip>
    </div>
  );
};
