import { useIsOwner } from 'components/NoAppState/NoAppState.hooks';
import { useAuthState } from 'modules/Auth/AuthContext';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useRouter } from 'next/router';
import { propOr } from 'rambda';

export enum NavLinkType {
  profile = 'profile',
  collaborations = 'collaborations',
  community = 'community',
  controlCenter = 'controlCenter',
  compute = 'compute',
  pendingVerification = 'pendingVerification',
}

const COMMUNITY_PAGES = ['/explore', '/explore/models', '/explore/modules', '/explore/workflows', '/explore/apps', '/explore/datasets'];

/**
 * Determines which NavLink type to show as active.
 */
export function useActiveNavLinkType(): NavLinkType | null {
  const { authData } = useAuthState();
  const router = useRouter();
  const currentUserId = useCurrentUserId();
  const isResourceOwner = useIsOwner();

  const loggedInUserId = propOr('', 'user_id')(authData || {});
  const pageUserOrOrgId = router.query?.userOrOrgId as string;
  const currentPathWithoutQueryParam = router.asPath?.split('?')[0];
  const isAnExplorePage = COMMUNITY_PAGES.some((page) => currentPathWithoutQueryParam === page);
  const isPageOfAnotherUserOrOrg = pageUserOrOrgId && pageUserOrOrgId !== currentUserId;

  if (router.pathname === '/pending-verification') {
    return NavLinkType.pendingVerification;
  }

  if (currentPathWithoutQueryParam.startsWith(`/${loggedInUserId}/collaborations`)) {
    return NavLinkType.collaborations;
  }

  if (currentPathWithoutQueryParam.startsWith(`/control-center`)) {
    return NavLinkType.controlCenter;
  }

  if (currentPathWithoutQueryParam.startsWith(`/settings/compute`)) {
    return NavLinkType.compute;
  }

  if (isResourceOwner) {
    return NavLinkType.profile;
  }

  if (isAnExplorePage || isPageOfAnotherUserOrOrg) {
    return NavLinkType.community;
  }

  return null;
}
